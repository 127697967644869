
import { createStore } from "vuex";
import { auth } from "./auth.store";
// import { home } from "./home.store";

export const store = createStore({
    // namespaced: true,
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,

    },
})