<template>
<div>
    <ion-menu menu-id="first" content-id="main" swipe-gesture="true">
        <ion-header>
            <ion-toolbar color="primary">
                <template v-if="dealer != null && dealer.hasOwnProperty('sid')">
                    <ion-title>{{dealer.name}}</ion-title>
                </template>
                <template v-else>
                    <ion-title>Menu</ion-title>
                </template>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-list>

                <ion-menu-toggle auto-hide="true" >
                    <ion-item menuClose @click="openWebshopUrl('/')">Collectie</ion-item>
                    <ion-item menuClose @click="openUrl('/locator')">Dealerlocator</ion-item>

                    <template v-if="dealer != null && dealer.hasOwnProperty('sid')">
                        <ion-item menuClose @click="openWebshopUrl('customer/account')">Account</ion-item>
                    </template>
                    <template v-else>
                        <ion-item menuClose @click="handleSignOut">Dealer login</ion-item>
                    </template>

                    <ion-list-header>Over Garden Impressions</ion-list-header>


                    <ion-list-header>Klantenservice</ion-list-header>

                    <ion-list-header>Inspiratie</ion-list-header>
                    <ion-item menuClose router-link="/page/blog">Blog</ion-item>

                    <ion-list-header>Informatie</ion-list-header>
                    <ion-item menuClose router-link="/page/disclaimer">Disclaimer</ion-item>

                </ion-menu-toggle>
            </ion-list>
        </ion-content>
    </ion-menu>
    <ion-header>
        <ion-toolbar color="primary" >
            <ion-buttons slot="start">
                <ion-menu-button></ion-menu-button>
            </ion-buttons>
            <ion-title>
                <img src="" class="logo" />
            </ion-title>
            <ion-buttons slot="primary">
                <ion-button color="" @click="handleSignOut">
                    <ion-icon slot="icon-only" :icon="logOut"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
  </div>
</template>

<script>
    import { IonItem, IonList, IonMenu, IonContent } from '@ionic/vue';
    import { logOut } from 'ionicons/icons';
    import { mapActions } from "vuex";
    import { useRouter } from 'vue-router';
    import localforage from 'localforage'

    import { Plugins } from '@capacitor/core';
    const { Browser } = Plugins;

    export default {
        name: 'appHeader',
        components: {
            IonItem,
            IonList,
            IonMenu,
            IonContent
        },
        data() {
            return {
                dealer: {},
            }
        },
        mounted() {
            this.getDealerSession();
        },
        setup() {
            const router = useRouter();
            return {
                router,
                logOut
            };
        },
        methods: {
            ...mapActions("auth", ["signOut"]),
            ...mapActions("home", ["loadSecretArea"]),
            async handleSignOut() {
                await this.signOut().then(() => {
                    this.router.push("/login");

                });
            },
            async getDealerSession(){
                this.dealer = await localforage.getItem('dealer');
                console.log('Dealer:');
                console.log(this.dealer);
            },
            async openWebshopUrl(redirectUrl = ''){
                if(this.dealer.sid != null && this.dealer.device_id != null && this.dealer.email != null){
                    if(redirectUrl != ''){
                        console.log(redirectUrl);
                        await Browser.open({ url: process.env.VUE_APP_ROOT_API + '/api/app/magento/login/dealer?device_id='+ this.dealer.device_id +'&sid='+this.dealer.sid+'&email=' + this.dealer.email + '&redirect_url=' + redirectUrl });
                    } else {
                        await Browser.open({ url: process.env.VUE_APP_ROOT_API + '/api/app/magento/login/dealer?device_id='+ this.dealer.device_id +'&sid='+this.dealer.sid+'&email=' + this.dealer.email});
                    }
                } else {
                    await Browser.open({ url: process.env.VUE_APP_MAGE_STORE_URL});
                    this.handleSignOut();
                }
            },
            async openUrl(url){
                if(url != ''){
                    await Browser.open({ url: process.env.VUE_APP_MAGE_STORE_URL + url });
                }
            }
        },
    }
  </script>

  <style>
    /* .ion-page {  } */
    #main { margin-top: 40px; background: #fefdf5 !important; }
  </style>