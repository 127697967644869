<template>
    <ion-page>
        <ion-content :fullscreen="true" padding class="content">
            <ion-progress-bar v-if="loading" slot="fixed" type="indeterminate"></ion-progress-bar>

            <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
                <ion-refresher-content></ion-refresher-content>
            </ion-refresher>

            <div v-if="loading" class="loading" id="center-container">
            <!-- Gegevens ophalen... -->
            </div>
            <div v-if="!loading" id="container">
                <template v-if="children.length == 0">
                    <div class="post-container">
                        <ion-card>
                            <!-- <img src="{{ page.featured_image }}" /> -->
                            <ion-card-header>
                                <ion-card-title>{{ page.title }}</ion-card-title>
                                <ion-card-subtitle>{{ page.subtitle }}</ion-card-subtitle>
                            </ion-card-header>
                            <ion-card-content v-html="page.content">

                            </ion-card-content>
                        </ion-card>
                    </div>
                </template>
                <template v-else>

                    <div class="children-container">
                        <ion-card v-for="child in children" :key="child.id">
                            <router-link :to="{ name: 'Page', params: { slug: child.identifier } }">
                                <div class="image-container">
                                    <img :src="child.featured_image" />
                                </div>
                                <ion-card-header>

                                        <ion-card-title>{{ child.subtitle }}</ion-card-title>
                                        <ion-card-subtitle>{{ child.title }}</ion-card-subtitle>
                                </ion-card-header>
                            </router-link>
                            <ion-card-content v-html="child.excerpt">
                            </ion-card-content>
                        </ion-card>
                    </div>
                </template>
            </div>
        </ion-content>
    </ion-page>

</template>

<script lang="ts">
import { IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonContent,  IonPage, IonRefresher, IonRefresherContent  } from '@ionic/vue';
import { defineComponent } from 'vue';

import axios from 'axios';

export default defineComponent({
    name: 'Page',
    data() {
        return {
            loading: false,
            slug: {},
            children: {},
            page: {}
        }
    },
    components: {
        IonContent,
        IonPage,
        IonRefresher,
        IonRefresherContent
    },
    watch: {
        '$route': 'getPage'
    },
    created(){
        this.slug = this.$route.params.slug;
        let _any: any;
        this.getPage(_any);
    },
    methods: {
        getPage(event: CustomEvent){
            this.slug = this.$route.params.slug;
            this.loading = true;

            axios.get(process.env.VUE_APP_ROOT_API + '/api/app/magento/content/' + this.slug)
            .then(response => {
                this.page = response.data.data.page;
                this.children = response.data.data.children;
                this.loading = false;

                    // event.target.complete();
            });
        },
        doRefresh(event: CustomEvent) {

            this.getPage(event);

        }
    }
});
</script>

<style >
ion-progress-bar {
    margin-top:4px;
}
#center-container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container h2 {
    text-transform: capitalize;
    margin-top:15px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}

#container ion-card, #container ion-item {
    background: #fff !important;
}

#container .post-container img {
    padding: 5px;
    float: none;
}

#container .children-container .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden !important;
    max-height:150px;
}
#container .children-container .image-container img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}
</style>