import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import Bedrijfsinformatie from '../views/Bedrijfsinformatie.vue';
import Page from '../views/Page.vue';
import Signin from '../views/Signin.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Signin',
    component: Signin
  },
  {
    path: '/page/:slug',
    name: 'Page',
    component: Page,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
