<template>
  <ion-app>
    <header-template ref="appHeader" />

    <ion-router-outlet id="main" />
  </ion-app>
</template>

<script lang="ts">
import HeaderTemplate from './views/HeaderTemplate.vue';
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    HeaderTemplate
  },
  data() {
     return {
      appHeader: 0,
    };
  },
  methods: {
    forceRerender() {
      console.log('test');
      this.appHeader += 1;
    }
  }

});
</script>
