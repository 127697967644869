import axios from "axios";
// import { store } from '@/store';
import { Plugins } from '@capacitor/core';
import localforage from 'localforage'

const { Device, Modals, Browser } = Plugins;

class AuthenticationError extends Error {
    errorCode: any;
    constructor(errorCode: any, message: string | undefined) {
        super(message);
        this.name = this.constructor.name;
        if (message != null) {
            this.message = message;
        }
        this.errorCode = errorCode;
    }
}

const AuthService = {
    signIn: async function(signInData: any) {
        const info = await Device.getInfo();
        console.log(info);
        try {
            axios.post(process.env.VUE_APP_ROOT_API + '/api/app/authenticate/dealer', {
                deviceUuid: info.uuid,
                email: signInData.email,
                password: signInData.password
            })
            .then(function (response) {

                // set localstorage with user model

                console.log('response:');
                console.log(response);

                if (response.data.status == false){
                    Modals.alert({
                        title: 'Stop',
                        message: response.data.message
                    });
                } else if (response.data.status == true) {
                    localforage.setItem('dealer', response.data.dealer);
                    localforage.setItem('forceReload', true);
                }
            })
            .catch(function (error) {
                console.log('error:');
                console.log(error);
            });
        } catch (error) {
            this.catchError(error);
        }
    },

    signOut() {
        // reset tokens from store
        localforage.removeItem('dealer');
        setTimeout(function () {
            window.location.reload(true);
        }, 500);

    },

    catchError: function (error: any) {
        let status;
        let description;

        if (error.response === undefined) {
            status = error.message;
            description = error.message;
        } else {
            status = error.response.status;
            description = error.response.data.error_description;
        }

        throw new AuthenticationError(status, description);
    }

}

export { AuthService, AuthenticationError };