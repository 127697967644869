<template>
  <ion-page>
    <ion-content class="ion-padding">
        <div id="">
        <form @submit.prevent="handleLogin">
        <!-- <ion-card> -->
            <ion-item lines="full">
            <h3>Dealer login</h3>
            </ion-item>

            <ion-item lines="full">
                <ion-label position="floating">E-mail</ion-label>
                <ion-input v-model="form.email" id="email" required></ion-input>
            </ion-item>

            <ion-item lines="full">
                <ion-label position="floating">Password</ion-label>
                <ion-input type="password" v-model="form.password" id="password" required></ion-input>
            </ion-item>

            <ion-row>
                <ion-col>
                    <ion-button  type="submit"  expand="block">
                        Login <ion-icon slot="end" :icon="logIn"></ion-icon>
                    </ion-button>
                </ion-col>
            </ion-row>

            <!-- <ion-item>
            <p>Or</p>
            </ion-item> -->
            <!-- <ion-item>
            <ion-button type="button" shape="round" router-link="/signup">
                Sign Up
                <ion-icon slot="end" :icon="personAdd"></ion-icon>
            </ion-button>
            </ion-item> -->
        <!-- </ion-card> -->
        </form>
        </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage, IonItem, IonLabel, IonButton, IonInput, alertController, IonIcon } from '@ionic/vue'
import { logIn, personAdd } from 'ionicons/icons';
import { mapActions } from "vuex"
import { useRouter } from 'vue-router';
// import localforage from 'localforage'


export default {
    name: 'SignIn',
    components: {
        IonContent,
        IonPage,
        IonItem,
        IonLabel,
        IonButton,
        IonInput,
        IonIcon
    },
    setup() {
        const router = useRouter();
        return {
            router,
            logIn,
            personAdd
        };
    },
    data() {
        return {
        form: {
            email: "",
            password: ""
        }
        };
    },
    computed: {

    },
    methods: {
        ...mapActions("auth", ["signIn"]),
        async handleLogin() {
            this.signIn(this.form).then(() => {

                this.form.email = "";
                this.form.password = "";
                this.router.push("/home");

                setTimeout(function(){
                    window.location.reload(true);
                }, 1000);

            }).catch(async (err: any) => {
                const errorAlert = await alertController
                    .create({
                        header: 'Failed',
                        subHeader: 'Sign in Failed',
                        message: err,
                        buttons: ['OK'],
                    });
                await errorAlert.present()
            });
        }
    }
}
</script>

<style scoped>
#container {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
</style>