
import { IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonContent,  IonPage, IonRefresher, IonRefresherContent  } from '@ionic/vue';
import { defineComponent } from 'vue';

import axios from 'axios';

export default defineComponent({
    name: 'Page',
    data() {
        return {
            loading: false,
            slug: {},
            children: {},
            page: {}
        }
    },
    components: {
        IonContent,
        IonPage,
        IonRefresher,
        IonRefresherContent
    },
    watch: {
        '$route': 'getPage'
    },
    created(){
        this.slug = this.$route.params.slug;
        let _any: any;
        this.getPage(_any);
    },
    methods: {
        getPage(event: CustomEvent){
            this.slug = this.$route.params.slug;
            this.loading = true;

            axios.get(process.env.VUE_APP_ROOT_API + '/api/app/magento/content/' + this.slug)
            .then(response => {
                this.page = response.data.data.page;
                this.children = response.data.data.children;
                this.loading = false;

                    // event.target.complete();
            });
        },
        doRefresh(event: CustomEvent) {

            this.getPage(event);

        }
    }
});
