
import { IonContent, IonPage, IonItem, IonLabel, IonButton, IonInput, alertController, IonIcon } from '@ionic/vue'
import { logIn, personAdd } from 'ionicons/icons';
import { mapActions } from "vuex"
import { useRouter } from 'vue-router';
// import localforage from 'localforage'


export default {
    name: 'SignIn',
    components: {
        IonContent,
        IonPage,
        IonItem,
        IonLabel,
        IonButton,
        IonInput,
        IonIcon
    },
    setup() {
        const router = useRouter();
        return {
            router,
            logIn,
            personAdd
        };
    },
    data() {
        return {
        form: {
            email: "",
            password: ""
        }
        };
    },
    computed: {

    },
    methods: {
        ...mapActions("auth", ["signIn"]),
        async handleLogin() {
            this.signIn(this.form).then(() => {

                this.form.email = "";
                this.form.password = "";
                this.router.push("/home");

                setTimeout(function(){
                    window.location.reload(true);
                }, 1000);

            }).catch(async (err: any) => {
                const errorAlert = await alertController
                    .create({
                        header: 'Failed',
                        subHeader: 'Sign in Failed',
                        message: err,
                        buttons: ['OK'],
                    });
                await errorAlert.present()
            });
        }
    }
}
