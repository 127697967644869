
import { IonContent,  IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonPage
  },
  setup() {
    return {

    }
  },
});
